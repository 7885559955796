/*
  Define your resets here or use something like Normalize.css if you like
 */

html,
body{
  margin: 0;
  padding: 0;
}

/* UTILITIES */

.cleafix:after {
  content: "";
  display: table;
  clear: both; }

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background: #fff; }

section {
  background: #fff;
  padding: 3.5em 2.5em; }

section .section-inner {
    margin: 0 auto;
    max-width: 50em; }

section .section-inner:after {
      content: "";
      display: table;
      clear: both; }

.min-full-height {
  min-height: 100vh; }

.min-half-height {
  min-height: 50vh; }

.hero--fade-edge:after {
  content: "";
  position: absolute;
  z-index: 300;
  bottom: 0;
  width: 100%;
  height: 5vh;
  display: block;
  background-image: linear-gradient(0deg, #111, rgba(17, 17, 17, .9), rgba(17, 17, 17, .1), rgba(17, 17, 17, 0)); }

.hero-intro .profile-image {
  margin: 0 auto 1.2em auto; }

@media (min-width: 500px) {
    .hero-intro .profile-image {
      margin: 0 1.6em 0.8em 0;
      float: left;
      max-width: none;
      width: 200px; } }

@media (min-width: 500px) {
  .hero-intro .main {
    float: right;
    width: calc(100% - 200px - 1.6em); } }

/* TYPOGRAPHY */

/* Some fundamentals moved into criticalcss */

.heading1 {
  font-family: "semplicitapro",Arial,sans-serif;
  font-weight: 300;
  text-transform: uppercase; }

strong.highlight {
  font-weight: normal;
  background: #10e2e2;
  padding: 0 0.3em; }

ul.inline-list, .inline-list ul {
  padding: 0; }

ul.inline-list:after, .inline-list ul:after {
    content: "";
    display: table;
    clear: both; }

ul.inline-list li, .inline-list ul li {
    list-style: none;
    float: left;
    margin-right: 0.3em; }

ul.inline-list li:after, .inline-list ul li:after {
      content: " /";
      color: #ccc; }

ul.inline-list li:last-of-type:after, .inline-list ul li:last-of-type:after {
      content: ""; }

p, ul, ol {
  margin: 0 0 0.8em; }

a:link {
  color: #00c1c1; }

a:visited {
  color: #00c1c1; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

/*
    $num: number of images.
    $fade: duration of the fade.
    $visible: duration slide is visible.
    @mixin fade($num:1, $fade:1, $visible:2) { }
*/

.profile-image img {
  max-width: 100%;
  height: auto;
  border-radius: 50%;
  margin: 0 auto;
  text-align: center;
  display: block; }

/* CANVAS */

/* Moved to critical css */

/* BROWSER */

/* Browser upgrade warning */

section.browserupgrade {
  background: #fff; }

section.browserupgrade:after {
  content: "";
  display: table;
  clear: both; }

section.browserupgrade input {
  margin-top: 1.5em; }

section.browserupgrade label {
  font-size: 12.8px;
  font-size: 0.8rem; }

section.browserupgrade p {
  font-size: 16px;
  font-size: 1rem;
  margin: 1em; }

input#browserupgrade:checked,
input#browserupgrade:checked + label,
input#browserupgrade:checked + label + p {
  display: none; }

/* No js warning */

section.no-js-message {
  background: #fff; }

section.no-js-message:after {
  content: "";
  display: table;
  clear: both; }

section.no-js-message input {
  margin-top: 1.5em; }

section.no-js-message label {
  font-size: 12.8px;
  font-size: 0.8rem; }

section.no-js-message p {
  font-size: 16px;
  font-size: 1rem;
  margin: 1em; }

input#no-js-message:checked,
input#no-js-message:checked + label,
input#no-js-message:checked + label + p {
  display: none; }

/* ARROWS */

.chevron::before {
  border-style: solid;
  border-width: 0.25em 0.25em 0 0;
  content: '';
  display: inline-block;
  height: 0.45em;
  left: 0.15em;
  position: relative;
  top: 0.15em;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  vertical-align: top;
  width: 0.45em; }

.chevron.right:before {
  left: 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

.chevron.bottom:before {
  top: 0;
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg); }

.chevron.left:before {
  left: 0.25em;
  -webkit-transform: rotate(-135deg);
          transform: rotate(-135deg); }

.networks-icons {
  line-height: 0;
  padding: 0;
  margin: 0; }

.networks-icons li {
    list-style: none;
    margin: 0;
    padding: 0; }

@media (min-width: 250px) {
      .networks-icons li {
        float: left; } }

.networks-icons li a {
      text-decoration: none;
      width: 2.5em;
      height: 2.5em;
      color: #10e2e2;
      fill: #10e2e2;
      display: block;
      padding: 7px;
      margin: 0 2px;
      -webkit-transform: translateY(0);
              transform: translateY(0);
      background: rgba(0, 0, 0, .0001);
      transition: all 0.2s cubic-bezier(0.45, -0.7, 0.1, 0.74); }

.networks-icons li a:after {
        content: attr(data-title);
        position: absolute;
        left: 50%;
        top: 130%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
        font-size: 15px;
        font-weight: 300;
        opacity: 0;
        transition: opacity 0.2s ease;
        letter-spacing: 0.05em;
        white-space: nowrap; }

.networks-icons li a:hover, .networks-icons li a:active, .networks-icons li a:active:focus {
        fill: yellow;
        -webkit-transform: translateY(-5px);
                transform: translateY(-5px);
        -webkit-backface-visibility: visible;
                backface-visibility: visible; }

.networks-icons li a:hover:after, .networks-icons li a:active:after, .networks-icons li a:active:focus:after {
          opacity: 1; }

/* HOME + hero canvas css */

.home_hero {
  background: #111; }

.home_hero:after {
  background-image: linear-gradient(0deg, #111, rgba(17, 17, 17, .9), rgba(17, 17, 17, .1), rgba(17, 17, 17, 0)); }

.home_hero_logo {
  position: absolute;
  top: 47vh;
  left: 50%;
  -webkit-transform: translate(-50%, -100%);
          transform: translate(-50%, -100%);
  /*width: 140%;*/
  max-width: 900px; }

.home_hero_logo_container svg {
  position: absolute;
  top: 40vh;
  left: 50%;
  -webkit-transform: translate(-50%, -100%);
          transform: translate(-50%, -100%);
  max-width: 900px; }

.home_hero_name {
  white-space: nowrap;
  font-family: "semplicitapro",Arial,sans-serif;
  font-weight: 500;
  /*text-transform: uppercase;*/
  font-size: 2.1em;
  margin: 0;
  padding: 0;
  position: absolute;
  left: 50%;
  top: 47%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 200;
  color: #fff;
  cursor: default;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0s; }

@media screen and (max-width: 450px) {
  .home_hero_name {
    font-size: 8vw; } }

.home_hero_name:hover {
  color: #fff; }

.home_hero_role {
  white-space: nowrap;
  font-family: "semplicitapro",Arial,sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 1em;
  letter-spacing: 0.05em;
  margin: 0;
  padding: 0;
  position: absolute;
  left: 0;
  top: calc(47% + 2.9em);
  z-index: 50;
  color: #fff;
  cursor: default;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

@media screen and (max-width: 450px) {
  .home_hero_role {
    font-size: 4vw; } }

ul.home_hero_role {
  text-align: center;
  width: 100%; }

ul.home_hero_role li {
    position: absolute;
    opacity: 0;
    -webkit-animation-name: fade;
            animation-name: fade;
    -webkit-animation-duration: 70s;
            animation-duration: 70s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    max-width: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none; }

@-webkit-keyframes fade {
  0% {
    opacity: 0; }
  0.7142857143% {
    opacity: 1; }
  5% {
    opacity: 1; }
  5.7142857143% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes fade {
  0% {
    opacity: 0; }
  0.7142857143% {
    opacity: 1; }
  5% {
    opacity: 1; }
  5.7142857143% {
    opacity: 0; }
  100% {
    opacity: 0; } }

ul.home_hero_role li:nth-child(1) {
      -webkit-animation-delay: 0s;
              animation-delay: 0s; }

ul.home_hero_role li:nth-child(2) {
      -webkit-animation-delay: 3.5s;
              animation-delay: 3.5s; }

ul.home_hero_role li:nth-child(3) {
      -webkit-animation-delay: 7s;
              animation-delay: 7s; }

ul.home_hero_role li:nth-child(4) {
      -webkit-animation-delay: 10.5s;
              animation-delay: 10.5s; }

ul.home_hero_role li:nth-child(5) {
      -webkit-animation-delay: 14s;
              animation-delay: 14s; }

ul.home_hero_role li:nth-child(6) {
      -webkit-animation-delay: 17.5s;
              animation-delay: 17.5s; }

ul.home_hero_role li:nth-child(7) {
      -webkit-animation-delay: 21s;
              animation-delay: 21s; }

ul.home_hero_role li:nth-child(8) {
      -webkit-animation-delay: 24.5s;
              animation-delay: 24.5s; }

ul.home_hero_role li:nth-child(9) {
      -webkit-animation-delay: 28s;
              animation-delay: 28s; }

ul.home_hero_role li:nth-child(10) {
      -webkit-animation-delay: 31.5s;
              animation-delay: 31.5s; }

ul.home_hero_role li:nth-child(11) {
      -webkit-animation-delay: 35s;
              animation-delay: 35s; }

ul.home_hero_role li:nth-child(12) {
      -webkit-animation-delay: 38.5s;
              animation-delay: 38.5s; }

ul.home_hero_role li:nth-child(13) {
      -webkit-animation-delay: 42s;
              animation-delay: 42s; }

ul.home_hero_role li:nth-child(14) {
      -webkit-animation-delay: 45.5s;
              animation-delay: 45.5s; }

ul.home_hero_role li:nth-child(15) {
      -webkit-animation-delay: 49s;
              animation-delay: 49s; }

ul.home_hero_role li:nth-child(16) {
      -webkit-animation-delay: 52.5s;
              animation-delay: 52.5s; }

ul.home_hero_role li:nth-child(17) {
      -webkit-animation-delay: 56s;
              animation-delay: 56s; }

ul.home_hero_role li:nth-child(18) {
      -webkit-animation-delay: 59.5s;
              animation-delay: 59.5s; }

ul.home_hero_role li:nth-child(19) {
      -webkit-animation-delay: 63s;
              animation-delay: 63s; }

ul.home_hero_role li:nth-child(20) {
      -webkit-animation-delay: 66.5s;
              animation-delay: 66.5s; }

.no-cssanimations ul.home_hero_role li:nth-child(1), .lte-ie10 ul.home_hero_role li:nth-child(1) {
      opacity: 1; }

ul.home_hero_role li h2 {
      font-weight: 300;
      font-size: 1em;
      margin: 0;
      padding: 0; }

.home_hero_role span {
  color: #000;
  font-weight: 500; }

.home_hero_role span:nth-child(1) {
  margin: 0 -0.15em; }

.home_hero_more {
  color: rgba(0, 0, 0, .5);
  font-size: 26px;
  position: absolute;
  left: 50%;
  bottom: 5vh;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: block;
  z-index: 201; }

@media (max-height: 450px) {
  .home_hero_more {
    display: none; } }

.home_hero .networks-icons {
  position: absolute;
  top: calc(47% + 7em);
  z-index: 202;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.home_hero_logo_container,
.home_hero_name,
.home_hero_role,
.networks-icons li a svg {
  opacity: 0; }

.no-cssanimations .home_hero_logo_container,
.no-cssanimations .home_hero_name,
.no-cssanimations .home_hero_role,
.no-cssanimations .networks-icons li a svg, .lte-ie10 .home_hero_logo_container,
.lte-ie10 .home_hero_name,
.lte-ie10 .home_hero_role,
.lte-ie10 .networks-icons li a svg {
  opacity: 1; }

.home_hero_logo_container {
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-delay: 1.1s;
          animation-delay: 1.1s;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }

.home_hero_name {
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-delay: 1.15s;
          animation-delay: 1.15s;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }

.home_hero_role {
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }

.networks-icons li a svg {
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-delay: 1.25s;
          animation-delay: 1.25s;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-backface-visibility: visible;
          backface-visibility: visible; }

.no-smil .no-smil-only {
  display: block !important; }

/*# sourceMappingURL=maps/main.css.map */
